//colors
$primary-color: #0583B9;
$secondary-color: #132851;
$paragraph-color:#707070;
$gray-606-color:#606060;
$black-color:#000000;
$link-color:#489DF0;
$border-color:#d0d0d0;
$circle-color:#37b4d2;
$white-color:#fff;
$wheat-color: #f0f0f0;
$shadow-color: rgba(19, 40, 81, 0.1);
$gradient-color-light:#18b9d4;
$gradient-color-dark:#0155ca;
$gray-color:gray;
$danger-color: red;


//fonts
$primary-font:Roboto;
$secondary-font: sans-serif;