@import "./variables.scss";


.App {
    border: 1px solid black;
    padding: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 1rem;
    height: 100%;
}

.welcome-container {
    margin: 40px 50px;

    h1 {
        color: $primary-color;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        font-family: $primary-font, $secondary-font;
    }

    h4 {
        color: $secondary-color;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        font-family: $primary-font, $secondary-font;
    }

    .img-container {
        width: 250px;
        height: 150px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.header-section {
    padding: 0 4px;
    border-bottom: 1px solid $border-color;
    display: flex;
    justify-content: space-around;
    height: auto;
    width: auto;

    .logo-container {
        width: 210px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            height: 100%
        }
    }

    .text-wrapper {
        margin: 5px 0;
        padding: 0 5px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        width: 150px;
        border-left: 1px solid $black-color;

        h1 {
            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;
            color: $secondary-color;
            font-family: Roboto;

            @media screen and (max-width: 240px) {
                font-size: 10px;
            }
        }
    }
}

.footer-section {
    border-top: 1px solid $border-color;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;

    .left-section {
        margin-top: -12px;
    }

    p {
        font-size: 17px;
        font-style: normal;
        font-family: $primary-font, $secondary-font;
        color: $paragraph-color;
        font-weight: 500px;

        @media screen and (max-width: 240px) {
            font-size: 11px;
        }
    }

    .icon-section {
        display: flex;
        justify-content: space-around;
        margin-top: -10px;

        img {
            width: 27px;
            height: 32px;
        }

        @media screen and (max-width: 240px) {
            img {
                width: 20px;
                height: 25px;
            }
        }
    }

    .link-section {
        text-align: center;

        p {
            font-size: 17px;
            font-style: normal;
            font-weight: 500px;
            margin-bottom: -5px;
            font-family: $primary-font, $secondary-font;
            color: $black-color;

            @media screen and (max-width: 240px) {
                font-size: 11px;
            }
        }

        a {
            color: $link-color;
            text-decoration: underLine;
            font-size: 14px;

            @media screen and (max-width: 240px) {
                font-size: 10px;
            }
        }

        margin-top: 5px;
    }
}

.main {
    margin-top: 2.5vh;
    margin-left: 15px;
    margin-right: 15px;

    .ticket-section {
        display: flex;
        justify-content: space-between;
        margin: 5px 0;

        .countryCode {
            margin-bottom: 10px;
            width: 55px;
            height: 23px;
            border: 2px solid $circle-color;
            border-radius: 8px;
            text-align: center;
            font-size: 13px;
            color: $gray-606-color;
            padding-top: 5px;

            span {
                margin-right: 5px;
            }
        }

        .ticket-font {
            font-size: 15px;
            line-height: 10px;
            font-weight: 600;
            color: $gray-606-color;
            display: block;
            font-family: $primary-font;

            @media screen and (max-width: 280px) {
                font-size: 12px;
            }
        }

        .right-section {
            margin-top: -15px;
            text-align: center;

            .ticket-circle {
                height: 25px;
                border: 1px solid $circle-color;
                border-radius: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 10px;
                font-size: 13px;
                color: $gray-606-color;
                width: 77px;
            }

            .fee-circle {
                height: 25px;
                border: 2px solid $circle-color;
                border-radius: 30px;
                display: flex;
                justify-content: center;
                background-color: $circle-color;
                align-items: center;
                margin: -5px 0 10px 0;
                color: $white-color;
                font-size: 13px;
                padding: 0 5px;
                width: fit-content;
                overflow: auto;

                span {
                    margin-left: 5px;
                }
            }
        }
    }

    .text-section {
        margin: 6vh 5px;
        width: 100%;

        @media screen and (max-height: 720px) {
            margin: 3vh 5px;
        }

        p {
            font-family: $primary-font;
            font-size: 23px;
            line-height: 22px;
            margin: 0;
            color: $gray-606-color;
            font-weight: 500px;
        }

        .content {
            min-height: 14vh;
            margin-top: 15px;

            p {
                font-size: 17px;
                font-style: normal;
                font-family: $primary-font;
                color: $paragraph-color;
                font-weight: 500px;

                @media screen and (max-width: 240px) {
                    font-size: 11px;
                }
            }

            .left-text {
                text-align: left;
                width: 100%;
                height: 9vh;
                overflow-wrap: break-word;
                word-wrap: break-word;
                hyphens: auto;
                margin-bottom: 3em;

                @media screen and (max-height: 750px) {
                    height: 10vh;
                }

                @media screen and (max-height: 580px) {
                    height: 50px;
                    font-size: 5px;
                    overflow-y: scroll;
                }
            }

            .right-text {
                text-align: right;
                width: 100%;
                height: 10vh;
                overflow-wrap: break-word;
                word-wrap: break-word;
                hyphens: auto;
                margin-bottom: 3em;

                @media screen and (max-height: 720px) {
                    height: 18vh;
                }

                @media screen and (max-height: 580px) {
                    height: 50px;
                    font-size: 5px;
                    overflow-y: scroll;
                }
            }
        }
    }
    .request-section {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4vh;
        margin-bottom: 6vh;
        @media screen and (max-height: 750px) {
            margin-top: 5vh;
            margin-bottom: 1vh;
        }

        @media screen and (min-height: 900px) {
            margin-top: 12vh;
            margin-bottom: 6vh;
        }
        .req-section{
            display: flex;
        }
    }
}

//card style
.request-card {
    width: 10rem;
    height: auto;
    border-radius: 35px;
    border: 1px solid $wheat-color;
    box-shadow: 4px 4px 10px $gray-color; 
    margin: 15px 0px;
    @media screen and (max-width: 300px) {
        width: auto;
    }
    padding: 2px 20px 0 2px;
    margin:20px 0px;

    .head-section {
        display: flex;
        justify-content: center;
        align-items: center;
 
        .img-wrapper {
            width: 55px;
            height: 50px;
button{
    background-color: transparent;
    border: none;
    cursor: pointer;
}
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .button-wrapper {
       
        button {
            background-color: transparent;
            border: none;
            cursor: pointer;
             width: 10rem;
             height: 3rem;
            span {
                background: linear-gradient(90deg, $gradient-color-dark 0%, $gradient-color-light 67.4%);
                font-family: $primary-font;
                font-size: 14px;
                font-style: normal;
                font-weight: 600px;
                line-height: 18px;
                text-transform: uppercase;
                margin-top: 10px;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;

                @media screen and (max-width: 240px) {
                    font-size: 8px;
                }
            }
        }
    }
}
.select-section{
    display: flex;
    justify-content: center;
    align-items: center;
    select {
        width: 160px;
        padding: 5px;
        height: 40px;
        border-radius: 12px;
        background-color: $white-color;
        border: solid 1px $circle-color;
        color: $circle-color;
        text-transform: capitalize;
      
    }

}
.card-button{
    background-color: transparent;
    border: none;
    cursor: pointer;
}
   


.error {
    color: $danger-color;
}

.status-card{     
    width: 10rem;
    height: auto;
       padding: 10px 2px 1px 1px ;
       border-radius: 35px;
       border: 1px solid $wheat-color;
       box-shadow: 4px 4px 10px $gray-color;
       
       margin: 15px 0px;
       @media screen and (max-width: 300px) {
           width: auto;
       }
   
       margin:20px 0px;
       .status-section {    
         p {
             font-size: 15px;
             font-style: normal;
             font-weight: bold;
             line-height: 10px;
             font-family: $primary-font;
             color: $gray-color;
             text-align: center;
         }
     }
       .head-section {
           display: flex;
           justify-content: center;
           align-items: center;
   
           .img-wrapper {
               width: 55px;
               height: 50px;
  
               img {
                   width: 100%;
                   height: 100%;
               }
           }
       }
   
}